<script>
export default {
  name: 'HeaderBar',
  data() {
    return {
      image: null,
      userInfo: {
        type: Object,
        default() {},
      },
      redirect: process.env.VUE_APP_LOGIN_REDIRECT,
    };
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        if (!clientPrincipal) {
          localStorage.userLoggedIn = false;
          this.$router.push({
            name: 'login',
          });
        }
        return clientPrincipal;
      } catch (error) {
        // console.error('No profile could be found');
        return undefined;
      }
    },
  },
  async mounted() {
    // this.userInfo = await this.getUserInfo();
    localStorage.userLoggedIn = true;
  },
};
</script>

<template>
  <div id="header">
    <div id="logo">
      <img
        class="logo"
        src="@/assets/humepay-logo.svg"
        alt="Hume Housing logo"
      />
    </div>
    <div id="heading">
      <h1>Online payment</h1>
      <div class="underline"></div>
    </div>
  </div>
</template>
