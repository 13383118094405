<script>
import HeaderBar from '@/components/header-bar.vue';
import NavBar from '@/components/nav-bar.vue';

export default {
  name: 'App',
  data() {
    return {};
  },
  components: {
    HeaderBar,
    NavBar,
  },
};
</script>

<template>
  <div id="app">
    <div class="container">
      <HeaderBar />
      <NavBar />
      <router-view />
    </div>
  </div>
</template>
