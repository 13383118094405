import Vue from 'vue';
import Router from 'vue-router';
import PageNotFound from '@/components/page-not-found.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/search',
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   props: true,
    //   component: () => import('./views/login.vue'),
    // },
    {
      path: '/payments',
      name: 'payments',
      props: true,
      component: () => import('./views/payments.vue'),
    },
    {
      path: '/process',
      name: 'process',
      props: true,
      component: () => import('./views/process.vue'),
    },
    {
      path: '/receipt',
      name: 'receipt',
      props: true,
      component: () => import('./views/receipt.vue'),
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('./views/search.vue'),
    },
    {
      path: '*',
      component: PageNotFound,
    },
  ],
});
