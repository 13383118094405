<template>
  <div id="progress" class="d-print-none">
    <ul class="progressbar">
      <li id="searchPB" v-bind:class="{ active: $route.name === 'search' }">
        <p><span>Find your account</span></p>
      </li>
      <li id="paymentPB" v-bind:class="{ active: $route.name === 'payments' }">
        <p><span>Review and pay</span></p>
      </li>
      <li id="receiptPB" v-bind:class="{ active: $route.name === 'receipt' }">
        <p><span>Your receipt</span></p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'NavBar',
};
</script>
