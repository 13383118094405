import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from '@/app.vue';
import VueCookies from 'vue-cookies';
import VueHtmlToPaper from 'vue-html-to-paper';
import LoadScript from 'vue-plugin-load-script';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './styles.scss';

const options = {
  name: '_blank',
  specs: ['fullscreen=no', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
};

Vue.use(LoadScript);
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueHtmlToPaper, options);

new Vue({
  router,
  validations: {},
  render: (h) => h(App),
}).$mount('#app');
